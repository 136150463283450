export const CRYPTO_CHAR_MAP: Record<string, string> = {
  '0': 'p',
  '1': 'Y',
  '2': 'b',
  '3': 'F',
  '4': 'm',
  '5': 'N',
  '6': '9',
  '7': 'r',
  '8': 'M',
  '9': 'P',
  A: 'j',
  B: 'L',
  C: 'T',
  D: 's',
  E: 'Q',
  F: 'H',
  G: 'l',
  H: 't',
  I: 'W',
  J: 'G',
  K: 'q',
  L: 'E',
  M: 'k',
  N: 'J',
  O: 'u',
  P: '5',
  Q: 'v',
  R: 'w',
  S: 'a',
  T: 'R',
  U: 'V',
  V: 'O',
  W: 'o',
  X: 'K',
  Y: '2',
  a: 'z',
  b: '4',
  c: 'h',
  d: '1',
  e: 'n',
  f: 'C',
  g: 'A',
  h: 'g',
  i: 'X',
  j: 'y',
  k: 'B',
  l: '8',
  m: '6',
  n: 'D',
  o: 'c',
  p: 'd',
  q: 'S',
  r: 'U',
  s: 'I',
  t: 'e',
  u: '7',
  v: 'i',
  w: '0',
  x: 'f',
  y: 'x',
  z: '3',
};
