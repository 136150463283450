import { Injectable } from '@angular/core';

import { CryptoService } from '@services/crypto/crypto.service';

import { TStorageCollection, TStorageCollectionEntry } from '@models/interfaces';

class MemoryStorage {
  private storage: Record<string, string> = {};

  public getItem(key: string): string {
    return this.storage[key];
  }

  public hasItem(key: string): boolean {
    return !!this.storage[key];
  }

  public setItem(key: string, value: string) {
    this.storage[key] = value;
  }

  public removeItem(key: string): void {
    delete this.storage[key];
  }
}

@Injectable({
  providedIn: 'root',
})
export class SpsStorageService {
  static readonly rootKey = 'SPS_STORAGE';
  private static _storage: Storage;

  static get storage(): Storage {
    if (this._storage) {
      return this._storage;
    }

    // fake storage on server
    try {
      this._storage = typeof localStorage === 'undefined' ? (new MemoryStorage() as unknown as Storage) : localStorage;
    } catch (e) {
      this._storage = new MemoryStorage() as unknown as Storage;
    }

    return this._storage;
  }

  static getEntry<T extends TStorageCollectionEntry>(key: string, storage: Storage = null): T {
    storage ||= this.storage;
    const collection = this.getCollection(storage);
    const entry = (collection[key] || {}) as T;

    if (!entry) {
      return {} as T;
    }

    if (entry.expires && (entry.expires as number) <= Date.now()) {
      this.removeEntry(key, storage);
      return {} as T;
    }

    return entry;
  }

  static saveEntry(key: string, entry: TStorageCollectionEntry, storage: Storage = null): void {
    storage ||= this.storage;
    const collection = this.getCollection(storage);
    collection[key] = entry;

    this.saveCollection(collection, storage);
  }

  static removeEntry(key: string, storage: Storage = this.storage): void {
    const collection = this.getCollection(storage);
    delete collection[key];

    this.saveCollection(collection, storage);
  }

  static updateEntry<T extends TStorageCollectionEntry>(key: string, entry: T, storage: Storage = null) {
    storage ||= this.storage;
    const collection = this.getCollection(storage);
    const collectionEntry = collection[key] || {};
    collection[key] = { ...collectionEntry, ...entry };

    this.saveCollection(collection, storage);
  }

  static saveCollection(collection: TStorageCollection, storage: Storage = null): void {
    storage ||= this.storage;
    const encrypted = this.encrypt(JSON.stringify(collection));
    storage.setItem(this.rootKey, encrypted);
  }

  static getCollection(storage: Storage = null): TStorageCollection {
    storage ||= this.storage;

    const raw = storage.getItem(this.rootKey);
    return raw ? JSON.parse(this.decrypt(raw)) : {};
  }

  static encrypt(base64: string): string {
    return CryptoService.encrypt(base64);
  }

  static decrypt(encrypted: string): string {
    return CryptoService.decrypt(encrypted);
  }
}
