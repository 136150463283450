// Simple pseudo-encryption

import { CRYPTO_CHAR_MAP } from '@services/crypto/crypto-charmap';
import { cloneDeep, shuffle } from 'lodash-es';

export class CryptoService {
  private static readonly charMap = CRYPTO_CHAR_MAP;

  static encrypt(input: string | object): string {
    input = typeof input === 'string' ? input : JSON.stringify(input);

    return btoa(input)
      .split('')
      .map(char => this.charMap[char] ?? char)
      .join('');
  }

  static decrypt(encrypted: string) {
    const charMapEntries = Object.entries(this.charMap);

    const base64 = encrypted
      .split('')
      .map(char => {
        const entry = charMapEntries.find(([_, value]) => value === char);
        return entry?.[0] || char;
      })
      .join('');

    return atob(base64);
  }

  // Weak caesar cypher
  static generateCharMap(): Record<string, string> {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYabcdefghijklmnopqrstuvwxyz0123456789';
    return shuffle(cloneDeep(chars).split('')).reduce((p, c, i) => ({ ...p, [chars[i]]: c }), {});
  }
}
